import * as THREE from 'three';
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";

import {vertexShadow, vertexShadowMap } from './shaders';
import { GUI } from 'three/examples/jsm/libs/lil-gui.module.min.js';


import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer'
import { ShaderMaterial } from 'three';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass'
import { BokehPass } from 'three/examples/jsm/postprocessing/BokehPass'


let data = {
    meshSettings : [
        {
            name: 'Sphere',
            material : {
                clearcoat: 0.7,
                clearcoatRoughness: 0.1,
                metalness: 0.1,
                roughness: 0.2,
                color: 0x1363DF,
                
                normalScale: new THREE.Vector2( 0.15, 0.15 ),
            },

            scale: [1.0, 1.0, 1.0]
        },
        {
            name: 'Cross',
            material : {
                clearcoat: 0.0,
                clearcoatRoughness: 0.1,
                metalness: 0.1,
                roughness: 0.7,
                color: 0x86b6fe,
                
                
            },
            scale : [0.3, 0.3, 0.3]
        },
        {
            name: 'Box',
            material : {
                clearcoat: 0.0,
                clearcoatRoughness: 0.1,
                metalness: 0.1,
                roughness: 0.7,
                color: 0x1db9fc,
                
                normalScale: new THREE.Vector2( 0.15, 0.15 ),
            },
            scale: [1.0, 1.0, 1.0]
        },
        {
            name: 'Capsule',
            material : {
                clearcoat: 0.7,
                clearcoatRoughness: 0.1,
                metalness: 0.1,
                roughness: 0.2,
                color: 0x1363DF,
                
                normalScale: new THREE.Vector2( 0.15, 0.15 ),
            },
            scale: [1.0, 1.0, 1.0]
        }
    ],

    generalSettings: {

        shadowTextureSize: 512,
        exposure: 0.9,
        toneMapping: true,
        useDevicePixelRatio: false,
        envMap: true

    },

    paramBake : {
        bake : false,
        baking: false,
        bakingStart: 0.0,
        duration: 17.0
    },

    paramsForces : {
        multiplier : 0.4
    },

    pointerData: {
        drag_time: 0,
        prevPointerEvent: 0
    }, 

    paramsDOF: {
        focus: 37.5,
        maxblur: 0.0083,
        aperture: 0.0001,
        aspect: 1.0
    },

    animationTexs:[],

    timePassed: 0

};


function onPointerMove() {

    if (Date.now() - data.pointerData.prevPointerEvent < 200)
        data.pointerData.drag_time += 0.1;
    else
        data.pointerData.drag_time = 0;

    data.pointerData.prevPointerEvent = Date.now();

}

window.addEventListener( 'pointermove', onPointerMove );

function shuffle(a) {
    var j, x, i;
    for (i = a.length - 1; i > 0; i--) {
        j = Math.floor(Math.random() * (i + 1));
        x = a[i];
        a[i] = a[j];
        a[j] = x;
    }
    return a;
}

function handleColorChange( color ) {

    return function ( value ) {

        if ( typeof value === 'string' ) {

            value = value.replace( '#', '0x' );

        }

        color.setHex( value );

    };

}

function guiMeshPhysicalMaterial( gui, primitive_name, material ) {

    const data = {
        color: material.color.getHex(),
        emissive: material.emissive.getHex()
    };

    const folder = gui.addFolder( primitive_name );

    folder.addColor( data, 'color' ).onChange( handleColorChange( material.color) );
    folder.addColor( data, 'emissive' ).onChange( handleColorChange( material.emissive ) );

    folder.add( material, 'roughness', 0, 1 );
    folder.add( material, 'metalness', 0, 1 );
    folder.add( material, 'reflectivity', 0, 1 );
    folder.add( material, 'clearcoat', 0, 1 ).step( 0.01 );
    folder.add( material, 'clearcoatRoughness', 0, 1 ).step( 0.01 );

}

function guiGeneral(gui, b, renderer, composer, light) 
{

    const generalParameters = gui.addFolder('General');
    const dofParameters = gui.addFolder( 'DOF' );
    const folderBake = gui.addFolder( 'Bake' );
    const folderForces = gui.addFolder( 'Forces' );

    generalParameters.add(data.generalSettings, 'shadowTextureSize', [128, 256, 512, 1024, 2048, 4096]).onChange((v) => { light.shadow.mapSize.width = v; light.shadow.mapSize.height = v;});
    generalParameters.add(data.generalSettings, 'toneMapping', true, false).onChange((v) => { renderer.toneMapping = v? THREE.ACESFilmicToneMapping: THREE.NoToneMapping});
    generalParameters.add(data.generalSettings, 'exposure', 0, 2).onChange((v) => { renderer.toneMappingExposure = v;});
    generalParameters.add(data.generalSettings, 'useDevicePixelRatio', true, false).onChange((v) => { 

        data.generalSettings.useDevicePixelRatio = v;

        renderer.setPixelRatio( v? window.devicePixelRatio : 1.0 );
        composer.setPixelRatio( v? window.devicePixelRatio : 1.0 );
    });

    dofParameters.add( data.paramsDOF, 'focus' , 0, 150).onChange((v) => { console.log(v) ; b.materialBokeh.uniforms['focus'].value = v; } );
    dofParameters.add( data.paramsDOF, 'aspect', 0, 10).onChange( (v) => { b.materialBokeh.uniforms['aspect'].value = v; });
    dofParameters.add( data.paramsDOF, 'maxblur', 0, 0.05).onChange( (v) => { b.materialBokeh.uniforms['maxblur'].value = v; });
    dofParameters.add( data.paramsDOF, 'aperture', 0, 0.004).onChange( (v) => { b.materialBokeh.uniforms['aperture'].value = v; });

    folderBake.add( data.paramBake, 'bake', false, true ).onChange(() => {
       
        if (data.paramBake.bake)
            data.paramBake.bakingStart = data.timePassed;
        if (!data.paramBake.bake)
            data.animationTexs = [];

    });

    folderBake.add( data.paramBake, 'duration', 1, 100 );
    folderForces.add( data.paramsForces, 'multiplier', -2, 2 );

}



(async () => {

    let sim, crossGeometry;


    var GRID_WIDTH = 65,
        GRID_HEIGHT = 130,
        GRID_DEPTH = 65;

    let particleDensity = 0.1;
    let gridCellDensity = 0.1;


    let renderer = new THREE.WebGLRenderer({
        antialias: true,
        precision: 'highp'
    });
    renderer.setPixelRatio( data.generalSettings.useDevicePixelRatio? window.devicePixelRatio : 1.0 );

    renderer.setSize( window.innerWidth, window.innerHeight );
    renderer.setClearColor(0x0, 0)

    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap; 

    renderer.toneMapping = data.generalSettings.toneMapping? THREE.ACESFilmicToneMapping: THREE.NoToneMapping;
    renderer.toneMappingExposure = data.generalSettings.exposure;

    // scene init

    let scene = new THREE.Scene();

    //camera setup

    let camera = new THREE.PerspectiveCamera( 30, window.innerWidth / window.innerHeight, 0.1, 1000 );
    camera.position.set( 42.057, 14.2313,  103.8319);
    camera.lookAt(1000, 1000, 0)
    data.paramsDOF.aspect = camera.aspect;
    scene.add(camera);

    const controls = new OrbitControls( camera, renderer.domElement );
    controls.object.position.set(42.057, 14.2313,  105.8319);
    controls.target = new THREE.Vector3(42.057-0.0915, 14.2313 + 0.0325, 105.8319 -0.977)

    //render passes
    let rp = new RenderPass(scene, camera);
    let b = new BokehPass(scene, camera, {focus : 37.5, maxblur: 0.0083, aperture: 0.0001, aspect: data.paramsDOF.aspect});

    let composer = new EffectComposer( renderer );
    composer.setPixelRatio( data.generalSettings.useDevicePixelRatio? window.devicePixelRatio : 1.0 );

    composer.addPass( rp );
    composer.addPass(b);

    window.addEventListener( 'resize', () => {

        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();

        data.paramsDOF.aspect = camera.aspect;
        b.materialBokeh.uniforms['aspect'].value = camera.aspect;
    
        renderer.setSize( window.innerWidth, window.innerHeight );
        composer.setSize( window.innerWidth, window.innerHeight );
        renderer.setPixelRatio( data.generalSettings.useDevicePixelRatio? window.devicePixelRatio : 1.0 );
        composer.setPixelRatio( data.generalSettings.useDevicePixelRatio? window.devicePixelRatio : 1.0 );

    } );


    let container = document.getElementsByClassName("container")[0];
    container.appendChild( renderer.domElement );
    let context = document.getElementsByTagName('canvas')[0];

    const gui = new GUI();
    
    
    //simulation init

    let wgl = new WrappedGL(context, { 
        antialias: false,
        depth: false 
    })

    let gl = wgl.gl;

    await new Promise((res) => {
        sim = new Simulator(wgl, () => {res(0)});
    })


    var gridCells = GRID_WIDTH * GRID_HEIGHT * GRID_DEPTH * gridCellDensity;

    //assuming x:y:z ratio of 2:1:1
    var gridResolutionX = Math.ceil(Math.pow(gridCells / 2, 1.0 / 3.0));
    var gridResolutionZ = gridResolutionX * 1;
    var gridResolutionY = gridResolutionX * 2;

    let particlesWidth = 512
    var desiredParticleCount = gridResolutionX * gridResolutionY * gridResolutionZ * particleDensity; //theoretical number of particles
    var particlesHeight = Math.ceil(desiredParticleCount / particlesWidth); //then we calculate the particlesHeight that produces the closest particle count

    var particlePositions = [];


    let flatParticlePositions = new Float32Array(3 * gridResolutionX * gridResolutionY * gridResolutionZ * particleDensity);
    let flatParticleUV = new Float32Array(2 * gridResolutionX * gridResolutionY * gridResolutionZ * particleDensity);
    for (var i = 0; i < gridResolutionX; i++)
    {
        for (var j = 0; j < gridResolutionY; j++)
        {
            for (var k = 0; k < gridResolutionZ; k++)
            {
                for (var t = 0; t < particleDensity; t++)
                {
                    let pos = [i + Math.random(), j + Math.random(), k + Math.random()]
                    flatParticlePositions[3 * particlePositions.length + 0] = pos[0];
                    flatParticlePositions[3 * particlePositions.length + 1] = pos[1]
                    flatParticlePositions[3 * particlePositions.length + 2] = pos[2]

                    

                    particlePositions.push([pos[0], pos[1], pos[2]])
                }
            }
        }
    }

    for (var y = 0; y < particlesHeight; ++y) {
        for (var x = 0; x < particlesWidth; ++x) {
            flatParticleUV[(y * particlesWidth + x) * 2] = (x + 0.5) / particlesWidth;
            flatParticleUV[(y * particlesWidth + x) * 2 + 1] = (y + 0.5) / particlesHeight;
        }
    }


    var gridSize = [GRID_WIDTH, GRID_HEIGHT, GRID_DEPTH];
    var gridResolution = [gridResolutionX, gridResolutionY, gridResolutionZ];

    let count = Math.ceil(gridResolutionX * gridResolutionY * gridResolutionZ * particleDensity) - 1;
    let range = Array.from(new Array(count), (x, i) => i);
    let randomInds = shuffle(range);




    let indsSphere = randomInds.slice(0, count /4);
    let indsCross = randomInds.slice(count/4, count/2);
    let indsCube = randomInds.slice(count/2, 3 * count/ 4);
    let indsBrand = randomInds.slice(3 * count/ 4, count);

    let sphereUVs = [];
    let crossUVs = [];
    let cubeUVs = [];
    let brandUVs = [];

    indsSphere.forEach((x) => {
        sphereUVs.push(flatParticleUV[2 * x]);
        sphereUVs.push(flatParticleUV[2 * x + 1]);
    })

    indsCross.forEach((x) => {
        crossUVs.push(flatParticleUV[2 * x]);
        crossUVs.push(flatParticleUV[2 * x + 1]);
    })

    indsCube.forEach((x) => {
        cubeUVs.push(flatParticleUV[2 * x]);
        cubeUVs.push(flatParticleUV[2 * x + 1]);
    })

    indsBrand.forEach((x) => {
        brandUVs.push(flatParticleUV[2 * x]);
        brandUVs.push(flatParticleUV[2 * x + 1]);
    })

    sim.reset(particlesWidth, particlesHeight, particlePositions, gridSize, gridResolution, particleDensity)

    //three texture to copy simulation framebuffer
    let sim_tex = new THREE.FramebufferTexture(particlesWidth, particlesHeight, 'RGBA16F')
    sim_tex.needsUpdate = true;

    //lights

    const spotLight = new THREE.SpotLight( 0xffffff );
    spotLight.intensity = 0.9;
    spotLight.position.set( 70, 50, 70 );
    spotLight.castShadow = true;

    const targetObject = new THREE.Object3D();

    targetObject.position.set(70, -150, 70)

    spotLight.target = targetObject;

    spotLight.target.updateMatrixWorld();

    scene.add(targetObject);

    spotLight.shadow.normalBias = 0.00
    spotLight.shadow.radius = 4.0
    spotLight.shadow.bias = - 0.0005
    spotLight.shadow.blurSamples = 16


    spotLight.shadow.mapSize.width = data.generalSettings.shadowTextureSize;
    spotLight.shadow.mapSize.height = data.generalSettings.shadowTextureSize; 

    scene.add( spotLight );

    var ambLight = new THREE.AmbientLight(0x1363DF);

    ambLight.intensity = 0.5;

    scene.add(ambLight);



    //load hdr texture for reflections
    await new Promise((res, rej) => {
        new RGBELoader()
            .load( './studio_small_09_1k.hdr', function ( texture ) {

                texture.mapping = THREE.EquirectangularReflectionMapping;

                scene.background = new THREE.Color(0xffffff);
                scene.environment = texture;
                res(0);
            })
        }
    );


    //load geometry
    await new Promise((res, rej) => {
        new OBJLoader()
            .load( './meshes/cross.obj', function ( object ) {
                crossGeometry = object.children[0].geometry; //OBJ
                res(0);
            })
        }
    );

    //redo materials

    THREE.ShaderLib.physical.uniforms.u_positionsTexture = {value : sim_tex}
    THREE.ShaderLib.depth.uniforms.u_positionsTexture = {value : sim_tex}
    THREE.ShaderLib.depth.vertexShader = vertexShadowMap;



    let depthMat = new ShaderMaterial({
        vertexShader: vertexShadowMap,
        fragmentShader: THREE.ShaderLib.depth.fragmentShader,
        uniforms: THREE.ShaderLib.depth.uniforms,
        defines: {'DEPTH_PACKING': 3201}
    });


    depthMat.uniforms.time = { value : data.timePassed }
    depthMat.needsUpdate = true;

    b.materialBokeh.onBeforeRender = (renderer) => {

        renderer.resetState();
    };

    b.materialDepth.onBeforeRender = (renderer) => {
        let props = renderer.properties.get( b.materialDepth );

        if (props.uniforms)
        {
            props.uniforms.u_positionsTexture.value = sim_tex;
            props.uniforms.time.value = data.timePassed;
        }
    }



    [[sphereUVs, new THREE.SphereGeometry(0.7, 6, 6)], [crossUVs,  crossGeometry], [cubeUVs, new THREE.BoxGeometry( 1, 1, 1 )], [brandUVs, new THREE.CapsuleGeometry( 0.5, 0.5, 2, 4 ) ]].forEach(([uvs, instance], index) => {

        
        let materialS = new THREE.MeshPhysicalMaterial(data.meshSettings[index].material);

        console.log(instance)


        materialS.uniforms =  {

            time: { value: data.timePassed }   
        }


        materialS.uniformsNeedUpdate = true;
        materialS.isShaderMaterial = true;

        materialS.envMapIntensity = 0.15;

        materialS.onBeforeRender = (renderer) => {
            let curr = renderer.getRenderTarget();
            //renderer.resetState();
            renderer.setRenderTarget( curr );
            materialS.uniforms.time.value = data.timePassed;
            materialS.uniformsNeedUpdate = true;

            THREE.ShaderLib.physical.vertexShader = vertexShadow;

            let props = renderer.properties.get( materialS );

            if (props.uniforms)
                props.uniforms.u_positionsTexture.value = sim_tex;


            if (props.programs && (props.programs.length != 0))
            {
                var gl = renderer.getContext();
                let vals = Array.from(props.programs.values());
                var p = vals[0];
                if (p.program)
                {
                    gl.useProgram( p.program );
                    var pu = p.getUniforms();
                    pu.setValue(gl, 'time', data.timePassed);
                    
                }
            }


        }

        var geometry = new THREE.InstancedBufferGeometry();
        geometry.index = instance.index;
        geometry.instanceCount = uvs.length / 2;



        geometry.setAttribute( 'position', instance.attributes.position );
        geometry.setAttribute('normal', instance.attributes.normal);
        geometry.setAttribute( 'uv', new THREE.InstancedBufferAttribute( new Float32Array( uvs ), 2 ) );

        let mesh = new THREE.Mesh( geometry, materialS );


        guiMeshPhysicalMaterial(gui, data.meshSettings[index].name, materialS)

        mesh.castShadow = true; //default is false
        mesh.receiveShadow = true;

        mesh.scale.set(data.meshSettings[index].scale[0], data.meshSettings[index].scale[1], data.meshSettings[index].scale[2])
        mesh.frustumCulled = false;


        mesh.customDepthMaterial = depthMat;


        scene.add( mesh );


    })

    guiGeneral(gui, b, renderer, composer, spotLight);

    function animate() {
        

        requestAnimationFrame( animate );
        gl.disable(gl.DEPTH_TEST)
        gl.disable(gl.CULL_FACE)

        data.timePassed += 0.05;

        depthMat.uniforms.time = {value : data.timePassed}

        if (Date.now() - data.pointerData.prevPointerEvent > 200)
            data.pointerData.drag_time = 0;

        if (data.paramBake.bake)
        {

            if (data.timePassed < data.paramBake.bakingStart + data.paramBake.duration)
                data.paramBake.baking = true;
            else
                data.paramBake.baking = false;

            if (data.paramBake.baking)
            {
                let simFrame = new THREE.FramebufferTexture(particlesWidth, particlesHeight, 'RGBA16F')
                simFrame.needsUpdate = true;

                sim.simulate(data.timePassed, data.paramsForces.multiplier, data.pointerData.drag_time);

                renderer.initTexture(simFrame)

                renderer.copyFramebufferToTexture(new THREE.Vector2(0, 0), simFrame);

                data.animationTexs.push(simFrame);

            }
            else
            {
                sim_tex = data.animationTexs[Math.round((((data.timePassed - (data.paramBake.bakingStart + data.paramBake.duration)) % data.paramBake.duration) / 0.05))];
            }
        }
        else
        {
            sim.simulate(data.timePassed, data.paramsForces.multiplier, data.pointerData.drag_time);

            renderer.initTexture(sim_tex)

            renderer.copyFramebufferToTexture(new THREE.Vector2(0, 0), sim_tex);
        }

        controls.update();

        renderer.resetState();

        composer.render()
        

    }


    requestAnimationFrame( animate );

})();
